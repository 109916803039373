import React from "react"
import { Desktop, Mobile } from "../../components/layouts/mediaQuery"
import img_my_right_arrow from "../../images/img_my_right_arrow.svg"
import img_my_right_arrow_mint from "../../images/img_my_right_arrow_mint.svg"
import img_ranking_is_mine from "../../images/img_ranking_is_mine.svg"
import DOWN from "../../images/DOWN.svg"
import KEEP from "../../images/KEEP.svg"
import UP from "../../images/UP.svg"

const RankItem = ({ title = "", list = [], onClick, type = "" }) => {
  return (
    <div className="my-dashboard-div-2-rank-view">
      <a
        className="my-dashboard-div-2-rank-title-view"
        onClick={e => {
          e.preventDefault()
          onClick()
        }}
      >
        <p>{title}</p>
        <Desktop>
          <img src={img_my_right_arrow} alt="right arrow img" />
        </Desktop>
        <Mobile>
          <div>
            <p>더보기</p>
            <img src={img_my_right_arrow_mint} alt="right arrow img" />
          </div>
        </Mobile>
      </a>
      <div className="my-dashboard-div-2-rank-list">
        {list.length > 0
          ? list.map((i, idx) => {
              if (idx < 5) {
                return (
                  <div key={idx} className="my-dashboard-div-2-rank-item">
                    <div className="text-box">
                      {i.isMine == 1 && <img className="is-mine" src={img_ranking_is_mine} alt="ismine img" />}
                      <p className="ranking">{idx + 1}</p>
                      <p className="title">{i.title || i.nickname || i.tag}</p>
                    </div>
                    {type == "" ? (
                      <p className="time">{i.totalTime}</p>
                    ) : (
                      <div className="up-down">
                        <p className="fluctuation">{i.ranking_fluctuation || "-"}</p>
                        <div className="img-wrapper">
                          <img src={i.ranking_up_down == "KEEP" ? KEEP : i.ranking_up_down == "UP" ? UP : DOWN}></img>
                        </div>
                      </div>
                    )}
                  </div>
                )
              } else {
                return <div key={idx}></div>
              }
            })
          : ["", "", "", "", ""].map((i, idx) => {
              return (
                <div key={idx} className="my-dashboard-div-2-rank-item">
                  <div>
                    <p>{idx + 1}</p>
                    <p></p>
                  </div>
                </div>
              )
            })}
      </div>
    </div>
  )
}

export default RankItem

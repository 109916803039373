/*
  마이렛미업 대시보드 페이지
*/
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setRankingPopup, setTodoTotalPopup, setConsultantProfilePopup, setBrainwavePopup } from "../../store/modules/popup"
import SwipeableViews from "react-swipeable-views"
import SEO from "../../components/seo"
import { Desktop, Mobile } from "../../components/layouts/mediaQuery"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import BestToDoItem from "../../components/my/BestToDoItem"
import RankItem from "../../components/my/RankItem"
import SummaryBox from "../../components/common/SummaryBox"
import BarChart from "../../components/common/BarChart"
import RankingPopup from "../../popups/RankingPopup"
import temp_banner from "../../images/temp_banner.svg"
import temp_m_banner from "../../images/temp_m_banner.svg"
import SaveOn from "../../images/SaveOn.svg"
import LikeOn from "../../images/LikeOn.svg"
import img_schedule_left from "../../images/img_schedule_left.svg"
import img_schedule_right from "../../images/img_schedule_right.svg"
import img_common_contents_card_right_orange from "../../images/img_common_contents_card_right_orange.svg"
import img_common_contents_card_right_green from "../../images/img_common_contents_card_right_green.svg"
import PrivateRoute from "../../components/privateRoute"
import MyContainer from "../../containers/MyContainer"
import { timeFn, rnMessageListener } from "../../utils/util"
import { useSelector } from "react-redux"
import { debounce } from "lodash"
import TodoSavePopup from "../../popups/TodoSavePopup"
import { navigate } from "gatsby-link"

// 마이렛미업 Dashboard
const Dashboard = props => {
  const {
    studyRank,
    keywordRank,
    bestStudyRank,
    setChartOption,
    myLetmeupData,
    getMyLetmeupStatistics,
    getMyLetmeupStatisticsKeyword,
    getMyLetmeupStatisticsStudyTime,
    getMyLetmeupStatisticsBestStudy,
    getTodosBest,
    todoBestList,
    postTodoSave,
    postTodoFavorite,
    getContentsRecommendation,
    contentsRecommendation,
    postContentsMy,
  } = props

  const dispatch = useDispatch()
  const rankingPopup = useSelector(state => state.popup.rankingPopup)
  const [tab, setTab] = useState(0)
  const [swiperIndex, setSwiperIndex] = useState(0)
  const [studyTimes, setSudyTimes] = useState([])
  const [planTimes, setPlanTimes] = useState([])
  const [labels, setLabels] = useState([])
  const [maxTime, setMaxTime] = useState(0)
  const [maxTimeStep, setMaxTimeStep] = useState(0)
  const [top100, setTop100] = useState({ list: [], title: "", type: "" })
  const [openSave, setOpenSave] = useState(false)
  const [openLike, setOpenLike] = useState(false)
  const cardColor = {
    "취업/자격증": 5,
    중학생: 1,
    고등학생: 2,
    대학생: 3,
    대입수험생: 4,
    공무원: 6,
    기타: 8,
    직장인: 7,
  }

  //차트데이터
  const BAR_DATA = {
    labels: labels,
    datasets: [
      {
        label: "공부시간",
        data: studyTimes,
        borderColor: "#28D7D2",
        backgroundColor: "#28D7D2",
      },
      {
        label: "목표시간",
        data: planTimes,
        borderColor: "#D1D5DB",
        backgroundColor: "#D1D5DB",
      },
    ],
  }

  //rank data get
  const getRank = async () => {
    await getMyLetmeupStatisticsStudyTime()
    await getMyLetmeupStatisticsKeyword()
    await getMyLetmeupStatisticsBestStudy()
    await getTodosBest()
    await getContentsRecommendation({ contentSeq: "", brandName: "" })
  }

  //차트데이터 get
  const getDate = async () => {
    await getMyLetmeupStatistics({ period: tab == 0 ? "TODAY" : tab == 1 ? "WEEK" : "MONTH" })
  }
  const saveFn = debounce(async (todoDaySeq, isCheck) => {
    let result = await postTodoSave({ todoDaySeq: todoDaySeq, isCheck: isCheck == "Y" ? "N" : "Y" })
    if (result) {
      if (isCheck == "N") setOpenSave(true)
      await getTodosBest()
      if (isCheck == "N") {
        setTimeout(() => {
          setOpenSave(false)
        }, 1200)
      }
    }
  }, 300)

  //좋아요
  const likeFn = debounce(async (todoDaySeq, isCheck) => {
    let result = await postTodoFavorite({ todoDaySeq: todoDaySeq, isCheck: isCheck == "Y" ? "N" : "Y" })

    if (result) {
      if (isCheck == "N") setOpenLike(true)
      await getTodosBest()
      if (isCheck == "N") {
        setTimeout(() => {
          setOpenLike(false)
        }, 1200)
      }
    }
  }, 300)

  useEffect(() => {
    getDate()
  }, [tab])

  useEffect(() => {
    getRank()
    return () => {
      dispatch(setTodoTotalPopup(false))
      dispatch(setConsultantProfilePopup({ open: false, data: {} }))
    }
  }, [])

  useEffect(() => {
    if (!rankingPopup) {
      setTop100({ list: [], title: "", type: "" })
    }
  }, [rankingPopup])

  useEffect(() => {
    if (Object.keys(myLetmeupData).length != 0) {
      setLabels(myLetmeupData.chart.labels)
      setSudyTimes(timeFn(myLetmeupData.chart.studyTimes, "arr"))
      setPlanTimes(timeFn(myLetmeupData.chart.planTimes, "arr"))

      setChartOption({
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false, // 차트 내 세로줄 제거
            },
          },
          y: {
            min: 0,
            ticks: {
              color: "#000000",
              callback: function (value) {
                return value + "시간"
              },
            },
            grid: {
              drawBorder: false, // y축 세로줄 제거
            },
          },
        },
      })
    }
  }, [myLetmeupData])
  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])
  return (
    <PrivateRoute>
      <Layout title="Dashboard">
        <TodoSavePopup open={openSave} onClose={() => setOpenSave(false)} contents={"저장완료"} img={SaveOn} />
        <TodoSavePopup open={openLike} onClose={() => setOpenLike(false)} contents={"좋아요!"} img={LikeOn} />
        <SEO title="마이렛미업" />
        <RankingPopup list={top100.list} title={top100.title} type={top100.type} />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            {/* 목표대비 일일 공부 시간 */}
            <div className="my-dashboard-div-1">
              <p>렛미업 전체 스터디 요약</p>
              {/* border container */}
              <div className="my-dashboard-div-1-inner">
                {/* 탭 */}
                <div className="my-dashboard-div-1-tab-view">
                  <button className={`btn my-dashboard-div-1-tab-item ${tab === 0 ? "active" : ""}`} onClick={() => setTab(0)}>
                    <p>Today</p>
                  </button>
                  <button className={`btn my-dashboard-div-1-tab-item ${tab === 1 ? "active" : ""}`} onClick={() => setTab(1)}>
                    <p>Week</p>
                  </button>
                  <button className={`btn my-dashboard-div-1-tab-item ${tab === 2 ? "active" : ""}`} onClick={() => setTab(2)}>
                    <p>Month</p>
                  </button>
                </div>

                <SummaryBox data={myLetmeupData} />

                <div className="my-dashboard-div-1-graph-view">
                  <p>목표대비 {tab == 0 ? "일일" : tab == 1 ? "주간" : "연간"} 공부 시간</p>
                  <BarChart data={BAR_DATA} />
                </div>
              </div>
              {/* 순위 */}
              <div className="my-dashboard-div-2">
                <RankItem
                  title="Study Keyword 순위"
                  list={keywordRank}
                  type="keyword"
                  onClick={() => {
                    setTop100({ list: keywordRank || [], title: "Study Keyword  순위", type: "keyword" })
                    dispatch(setRankingPopup(true))
                  }}
                />
                <RankItem
                  title="베스트 스터디 그룹 순위"
                  list={bestStudyRank}
                  onClick={() => {
                    setTop100({ list: bestStudyRank || [], title: "베스트 스터디 그룹 순위", type: "" })
                    dispatch(setRankingPopup(true))
                  }}
                />
                <RankItem
                  title="누적 공부시간 순위"
                  list={studyRank}
                  onClick={() => {
                    setTop100({ list: studyRank || [], title: "누적 공부시간 순위", type: "" })
                    dispatch(setRankingPopup(true))
                  }}
                />
              </div>
              {/* Today's TO-DO BEST */}
              <div className="my-dashboard-div-3">
                <div className="my-dashboard-div-sub-title-div">
                  <p className="my-dashboard-div-sub-title">Today's TO-DO BEST</p>
                  <button onClick={() => dispatch(setTodoTotalPopup(true))} className="btn btn-primary font-white">
                    전체보기
                  </button>
                </div>

                <div className="my-dashboard-div-3-swiper-wrapper">
                  {todoBestList.web.length > 1 && (
                    <button
                      className="btn swiper-left"
                      onClick={() => {
                        if (swiperIndex === 0) return
                        setSwiperIndex(prev => prev - 1)
                      }}
                    >
                      <img src={img_schedule_left} alt="left img" />
                    </button>
                  )}
                  {todoBestList.web.length > 1 && (
                    <button
                      className="btn swiper-right"
                      onClick={() => {
                        if (swiperIndex < todoBestList.web.length - 1) setSwiperIndex(prev => prev + 1)
                      }}
                    >
                      <img src={img_schedule_right} alt="right img" />
                    </button>
                  )}

                  <Desktop>
                    {todoBestList.web.length > 0 && (
                      <SwipeableViews
                        className="my-dashboard-div-3-swiper-view"
                        enableMouseEvents
                        index={swiperIndex}
                        onChangeIndex={idx => setSwiperIndex(idx)}
                      >
                        {todoBestList.web.map((i, idx) => (
                          <div key={idx} className="my-dashboard-div-3-swiper-item">
                            {i.length > 0 && i.map((data, index) => <BestToDoItem saveFn={saveFn} likeFn={likeFn} data={data} key={index} />)}
                          </div>
                        ))}
                      </SwipeableViews>
                    )}
                  </Desktop>
                  <Mobile>
                    {todoBestList.mo.length > 0 && (
                      <SwipeableViews
                        className="my-dashboard-div-3-swiper-view"
                        enableMouseEvents
                        index={swiperIndex}
                        onChangeIndex={idx => setSwiperIndex(idx)}
                      >
                        {todoBestList.mo.map((i, idx) => (
                          <div key={idx} className="my-dashboard-div-3-swiper-item">
                            <BestToDoItem data={i} saveFn={saveFn} likeFn={likeFn} />
                          </div>
                        ))}
                      </SwipeableViews>
                    )}
                  </Mobile>
                </div>
                <Desktop>
                  <div className="my-dashboard-div-3-swiper-indicator-view">
                    {todoBestList.web.length > 0 &&
                      todoBestList.web.length <= 10 &&
                      todoBestList.web.map((i, idx) => (
                        <div
                          key={idx}
                          className={`my-dashboard-div-3-swiper-indicator-item ${swiperIndex === idx ? "active" : ""}`}
                          onClick={() => setSwiperIndex(idx)}
                        ></div>
                      ))}
                    {todoBestList.web.length > 10 && (
                      <p>
                        {swiperIndex + 1} / {todoBestList.web.length}
                      </p>
                    )}
                  </div>
                </Desktop>
                <Mobile>
                  <div className="my-dashboard-div-3-swiper-indicator-view">
                    {todoBestList.mo.length > 0 &&
                      todoBestList.mo.length <= 10 &&
                      todoBestList.mo.map((i, idx) => (
                        <div
                          key={idx}
                          className={`my-dashboard-div-3-swiper-indicator-item ${swiperIndex === idx ? "active" : ""}`}
                          onClick={() => setSwiperIndex(idx)}
                        ></div>
                      ))}
                    {todoBestList.mo.length > 10 && (
                      <p>
                        {swiperIndex + 1} / {todoBestList.web.length}
                      </p>
                    )}
                  </div>
                </Mobile>
              </div>
              {/* 배너 */}
              <div className="my-dashboard-div-4">
                <Desktop>
                  <img src={temp_banner} alt="banner img" />
                </Desktop>
                <Mobile>
                  <img src={temp_m_banner} alt="banner img" />
                </Mobile>
              </div>
              {/* 추천 컨텐츠 */}
              <div className="my-dashboard-div-5">
                <p>추천 컨텐츠</p>
                <div className="row">
                  {contentsRecommendation.map((i, idx) => {
                    if (idx > 2) return false
                    return (
                      <div key={idx} className="col-lg-4 common-contents-card-box common-contents-card-box-margin">
                        <div className={`card common-contents-card common-contents-card-${cardColor[i.studentGroupName]}`}>
                          <div className="title-view">{i.brandName}</div>
                          <div className="body-view">
                            <>
                              <p className="category">{`${i.categoryDepth1 || ""} ${i.categoryDepth2 || ""} ${i.categoryDepth3 || ""}`}</p>
                              <p className="title">
                                {i.title} ({(i.categoryDepth2 || "") + "/" + i.teacherName})
                              </p>
                            </>
                            <div>
                              <p className="percent">{i.discountRate}%</p>
                              <p className="sales-amt">{(i.partnerPrice || 0).toLocaleString()}원</p>
                              <p className="default-amt">{(i.letmeupPrice || 0).toLocaleString()} 원</p>
                              <img
                                // className="btn"
                                onClick={async () => {
                                  await postContentsMy({ contentSeq: i.contentSeq })
                                  if (window.ReactNativeWebView) {
                                    dispatch(setBrainwavePopup({ open: true, data: { seq: i.contentSeq, url: i.homepageUrl } }))
                                  } else {
                                    window.open(i.homepageUrl, "_blank")
                                  }
                                }}
                                src={img_common_contents_card_right_orange}
                                alt="right img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default MyContainer(Dashboard)
